import React from 'react';
import styled from 'styled-components';
import { primaryColor } from '../../config/theme';

const StyledStepCardTitle = styled.div`
  font-size: 24px;
  color: ${primaryColor};
  padding: 0 16px;
`;

const StepCardTitle = ({ text = '' }) => (
  <StyledStepCardTitle>{text}</StyledStepCardTitle>
);

export default StepCardTitle;
