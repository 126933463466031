import React from 'react';

export const PlayArrowIcon = () => (
  <svg
    width="11px"
    height="14px"
    viewBox="0 0 11 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Video-option-1"
        transform="translate(-1262.000000, -480.000000)"
        fill="#196ECF"
      >
        <g id="Group-11" transform="translate(1150.000000, 479.000000)">
          <g id="Group-10" transform="translate(112.000000, 1.000000)">
            <polygon id="Icon-Color" points="0 0 0 14 11 7" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const DownloadArrowIcon = () => (
  <svg
    width="14px"
    height="17px"
    viewBox="0 0 14 17"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Video-option-1"
        transform="translate(-1288.000000, -480.000000)"
        fill="#196ECF"
      >
        <g id="Group-11" transform="translate(1150.000000, 479.000000)">
          <g id="Group-10" transform="translate(112.000000, 1.000000)">
            <path
              d="M40,6 L36,6 L36,0 L30,0 L30,6 L26,6 L33,13 L40,6 L40,6 Z M26,15 L26,17 L40,17 L40,15 L26,15 L26,15 Z"
              id="Icon-Color"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const CopyIcon = () => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Video-option-1"
        transform="translate(-1150.000000, -479.000000)"
        fill="#196ECF"
      >
        <g id="Group-11" transform="translate(1150.000000, 479.000000)">
          <path
            d="M2,4 L0,4 L0,18 C0,19.1 0.9,20 2,20 L16,20 L16,18 L2,18 L2,4 L2,4 Z M18,0 L6,0 C4.9,0 4,0.9 4,2 L4,14 C4,15.1 4.9,16 6,16 L18,16 C19.1,16 20,15.1 20,14 L20,2 C20,0.9 19.1,0 18,0 L18,0 Z M17,9 L7,9 L7,7 L17,7 L17,9 L17,9 Z M13,13 L7,13 L7,11 L13,11 L13,13 L13,13 Z M17,5 L7,5 L7,3 L17,3 L17,5 L17,5 Z"
            id="Icon-Color"
          />
        </g>
      </g>
    </g>
  </svg>
);
