import React from 'react';
import styled from 'styled-components';
import { defaultBorderColor, primaryColor } from '../../config/theme';

const StyledToggleControl = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  .toggle-control {
    width: 42px;
    height: 24px;
    border: 1px solid ${defaultBorderColor};
    background-color: ${defaultBorderColor};
    border-radius: 12px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      background-color: #fff;
    }
  }

  .toggle-label {
    margin-left: 12px;
  }

  &.checked {
    .toggle-control {
      border: 1px solid ${primaryColor};
      background-color: ${primaryColor};

      &:before {
        left: auto;
        right: 0;
      }
    }
  }
`;

const ToggleControl = ({
  className,
  onChange,
  value,
  activeLabel = 'Off',
  inactiveLabel = 'On',
}) => {
  const handleChange = () => {
    onChange(!value);
  };

  return (
    <StyledToggleControl
      className={`${value ? 'checked' : ''} ${className}`}
      onClick={handleChange}
    >
      <div className="toggle-control" />
      <div className="toggle-label">{value ? activeLabel : inactiveLabel}</div>
    </StyledToggleControl>
  );
};

export default ToggleControl;
