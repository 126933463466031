import React from 'react';
import { HelpOutlineOutlined as HelpOutlinedIcon } from '@mui/icons-material';
import styled from 'styled-components';
import Tooltip from "../Tooltip/Tooltip";
import {tertiaryColor} from "@clatter/ui";

const StyledSlidesCounter = styled.div`
  background: ${tertiaryColor};
  padding: 12px 20px;
  border-radius: 3px;
  display: flex;
  justify-content: center;

  p {
    margin: 0;
  }

  span {
    font-weight: bold;
  }

  svg {
    cursor: pointer;
    font-size: 1.1rem;
    color: grey;
    margin: auto auto auto 10px;
  }
`;

const SlidesCounter = ({ slidesLimit, slidesCount }) => (
  <StyledSlidesCounter>
    <p>
      Slide Count: &nbsp;
      <span>
          {slidesCount} / {slidesLimit}
        </span>
    </p>
    <Tooltip text={`Presentations can have a maximum of ${slidesLimit} slides.`} left>
      <HelpOutlinedIcon />
    </Tooltip>
  </StyledSlidesCounter>
);


export default SlidesCounter
